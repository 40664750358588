import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BorderLine from "../components/borderline"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="homepage">
    {/*<h2 className="main"><a rel="noreferrer" target="_blank" href="https://www.kickstarter.com/projects/grand-guess-hotel/the-grand-guess-hotel">THE GRAND GUESS HOTEL IS NOW LIVE ON KICKSTARTER. CHECK IN HERE!</a></h2>*/}

    <h2 className="main">THE GRAND GUESS HOTEL WILL LAUNCH IN EARLY 2022. PLEASE SIGN UP TO OUR MAILING LIST FOR ALL THE LATEST NEWS!</h2>
    
    <div className="contentholder">

    	{/*<StaticImage
      src="../images/Grand_Guess_Hotel_Kickstarter_Sep_2021_1221.jpg"
      width={1000}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"
    />*/}

    <iframe width="560" height="315" src="https://www.youtube.com/embed/o8u3adwYOGU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    </div>

    <p>The Grand Guess Hotel is a ridiculous residence where only the most privileged patrons can reside. Unfortunately, we're fully booked for the next 100 years. However, as a token of the hotel's gratitude, we're launching a game where you can guess who our spectacular guests are. Enjoy!</p>

    </div>
  </Layout>
)

export default IndexPage
